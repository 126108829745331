import { type } from "@testing-library/user-event/dist/type";
import React, { useState, useEffect, useRef } from "react";

export const useUnload = (fn) => {
    const cb = React.useRef(fn)
    useEffect(() => {
        const onUnload = cb.current
        window.addEventListener('beforeunload', onUnload)
        return () => {
            window.removeEventListener('beforeunload', onUnload)
        }
    }, [cb])
}