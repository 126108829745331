import styled from 'styled-components'

export const List = styled.ul`
display: flex;
padding: 0;
max-width: ${({ maxWidth = '400px' }) => maxWidth};
width: ${({ listWidth = '90%' }) => listWidth};
flex-wrap: wrap;
flex-direction: column;
gap: 10px;

`;

export const Text = styled.p`
color: var(--secondary);

`;

export const Check = styled.div`
height: 15px;
width: 15px;
/* background: ${({ checked }) => checked ? 'green' : 'yellow'}; */
border: ${({ checked }) => checked ? '3px solid #fff' : '3px solid rgba(255,255,255,0.25)'};
display: flex;
  position: relative;
  border-radius: 100%;
  height: 25px;
  width: 25px;
	/* z-index: 5; */
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
:before {
    display: block;
  /* position: absolute; */
	content: '';
  border-radius: 100%;
  height: 11px;
  width: 11px;
  /* top: 5px;
	left: 5px; */
  margin: auto;
  background: ${({ checked }) => checked ? '#fff' : 'transparent'};
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}
`;

export const ListItem = styled.li`
    /* height: 40px; */
    list-style: none;
    text-align: center;
    display: flex;
    align-items: center;
    p{
        font-size: 0.8rem;
        margin: 0;
        color: var(--secondary);
    }
    h3{
        margin: 0;
    }
    gap: 5px;

    /* input[type=radio]:checked ~ .check {
        border: 5px solid #0DFF92; */
/* } */
    
`;